import { Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { DragIcon } from '../../../../assets/dragIcon';
import { debugLog } from '../../../../Common/Logger';
import { getTreeDataStart } from '../redux/sliceDefectCode';
import Arrow from '../../../../assets/arrowRignt.svg';
import { RootState } from '../../../../redux/rootState';
import { TreeNodeData } from '../utils/TypeAnalystMenu';
import { Empty } from '../../../ScreenAddSmc/Utils/TypeSmc';
import { setLoader } from '../../../../redux/loaderSlice';

// Interface for items
interface Item {
  id: string;
  content: string;
}

// Helper function to reorder items within a list
const reorder = (list: Item[], startIndex: number, endIndex: number): Item[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// Function to move an item between two lists
const move = (
  source: Item[],
  destination: Item[],
  droppableSource: { index: number },
  droppableDestination: { index: number },
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);

  return {
    sourceList: sourceClone,
    destList: destClone,
  };
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties | undefined): React.CSSProperties => ({
  userSelect: 'none',
  width: '100%',
  borderRadius: '8px',
  padding: grid * 2,
  border: '1px solid #E9EBEC',
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? '#c9ead9' : '',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? 'white' : 'white',
  padding: grid,
  borderRadius: '5px',
  // width: 250,
  minHeight: 300,
  maxHeight: 600,
  overflow: 'auto',
});

function ComponentDragAndDropList() {
  const { treeDataList, isLoading } = useSelector((state: RootState) => state.defectCodeReducer);
  const [items, setItems] = useState<Item[]>([]);
  const [selected, setSelected] = useState<Item[]>([]);
  const dispatch = useDispatch();

  function filterTypeId101(data: TreeNodeData[]) {
    const result: Item[] = [];

    function traverse(node: TreeNodeData) {
      if (node?.TypeId === 101) {
        result?.push({ id: String(node?.Id), content: node?.Name });
      }
      if (node?.Children) {
        node?.Children?.forEach((child) => traverse(child));
      }
    }

    traverse(data as Empty);
    return result;
  }

  useEffect(() => {
    if (treeDataList) {
      const filteredItems = filterTypeId101(treeDataList);
      setItems(filteredItems);
    }
  }, [treeDataList]);

  const getList = (id: string): Item[] => (id === 'droppable' ? items : selected);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const reorderedItems = reorder(getList(source.droppableId), source.index, destination.index);

      if (source.droppableId === 'droppable') {
        setItems(reorderedItems);
      } else {
        setSelected(reorderedItems);
      }
    } else {
      const { sourceList, destList } = move(getList(source.droppableId), getList(destination.droppableId), source, destination);

      setItems(source.droppableId === 'droppable' ? sourceList : destList);
      setSelected(destination.droppableId === 'droppable2' ? destList : sourceList);
    }
  };

  const handleGetSelectedData = () => {
    debugLog('Selected items:', selected);
  };

  useEffect(() => {
    dispatch(getTreeDataStart());
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  return (
    <div className="div_sites_container">
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Droppable droppableId="droppable">
              {(droppableProvided, droppableSnapshot) => (
                <>
                  <div className="customerInfo_container" style={{ backgroundColor: '#00a751', borderRadius: '5px' }}>
                    <div style={{ fontSize: '15px', fontWeight: 600, color: 'white' }}>
                      Items
                    </div>
                  </div>
                  <div ref={droppableProvided.innerRef} style={getListStyle(droppableSnapshot.isDraggingOver)}>
                    {items.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(draggableProvided, draggableSnapshot) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                          >
                            <div style={{
                              display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center',
                            }}
                            >
                              <div style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                              }}
                              >
                                <img src={Arrow} alt="Edit folder" />
                                <label
                                  className="container_label_draggable"
                                >
                                  {item.content}
                                </label>
                              </div>
                              <DragIcon />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                </>
              )}
            </Droppable>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Droppable droppableId="droppable2">
              {(droppableProvided, droppableSnapshot) => (
                <>
                  <div className="customerInfo_container" style={{ backgroundColor: '#00a751', borderRadius: '5px' }}>
                    <div style={{ fontSize: '15px', fontWeight: 600, color: 'white' }}>
                      Selected Items
                    </div>
                  </div>
                  <div ref={droppableProvided.innerRef} style={getListStyle(droppableSnapshot.isDraggingOver)}>
                    {selected.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(draggableProvided, draggableSnapshot) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                          >
                            <div style={{
                              display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center',
                            }}
                            >
                              <div style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                              }}
                              >
                                <img src={Arrow} alt="Edit folder" />
                                <label
                                  className="container_label_draggable"
                                >
                                  {item.content}
                                </label>
                              </div>
                              <DragIcon />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                </>
              )}
            </Droppable>
          </Grid>
        </Grid>
      </DragDropContext>
      <Button
        className="button_save_and_next"
        disabled={items.length !== 0}
        type="button"
        onClick={handleGetSelectedData}
        style={{
          marginTop: '16px',
          position: 'absolute',
          bottom: '20px',
          right: '20px',
        }}
      >
        Save
      </Button>
    </div>
  );
}

export default ComponentDragAndDropList;
